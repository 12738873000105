<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(storageSpaceMedia, index) in storageSpaceMediasData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="storageSpaceMedia.storageSpaceMediaNameCurrent"
      :description="storageSpaceMedia.fullCode"
      :imagePath="defaultImg"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <button
          :title="$t('info')"
          @click="
            setStorageSpaceMediaData(storageSpaceMedia);
            openBottomSheet('StorageSpaceMediaInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setStorageSpaceMediaData(storageSpaceMedia);
            openBottomSheet('StorageSpaceMediaQRCode');
          "
          :title="$t('QR.modelName')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeEdit">
        <button
          @click="
            setStorageSpaceMediaData(storageSpaceMedia);
            openBottomSheet('StorageSpaceMediaUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeFinaleDelete">
        <button
          v-b-modal.StorageSpaceMediaDelete
          :title="$t('delete')"
          @click="setStorageSpaceMediaData(storageSpaceMedia)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeChangeActivationType">
        <button
          v-b-modal.StorageSpaceMediaChangeActivationType
          :title="$t('changeActivationType')"
          @click="setStorageSpaceMediaData(storageSpaceMedia)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setStorageSpaceMediaData(storageSpaceMedia);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasStorageSpaceMediaEmployeeEdit,
  hasStorageSpaceMediaEmployeeFinaleDelete,
  hasStorageSpaceMediaEmployeeChangeActivationType,
  hasStorageSpaceMediaPlaceEdit,
  hasStorageSpaceMediaPlaceFinaleDelete,
  hasStorageSpaceMediaPlaceChangeActivationType,
} from "./../../../utils/privilegeHelper";
import CustomCard from "./../../../components/general/CustomCard.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["storageSpaceMediasData", "filterData", "defaultImg"],
  data() {
    return {
      modelName: this.$route.meta.modelName,
      storageSpacesMediaRoute: this.$route.meta.StorageSpacesMediaRoute,
    };
  },
  methods: {
    setStorageSpaceMediaData(storageSpaceMedia) {
      this.$emit("setStorageSpaceMediaData", storageSpaceMedia);
    },
    isDataExist,
    fullPathFileFromServer,
  },
  computed: {
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(hasStorageSpaceMediaEmployeeEdit());
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(hasStorageSpaceMediaPlaceEdit());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(hasStorageSpaceMediaEmployeeFinaleDelete());
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(hasStorageSpaceMediaPlaceFinaleDelete());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(
            hasStorageSpaceMediaEmployeeChangeActivationType()
          );
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(
            hasStorageSpaceMediaPlaceChangeActivationType()
          );
          break;
        default:
          break;
      }
      return status;
    },
  },
};
</script>
