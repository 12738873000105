var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"defaultImg":_vm.fullPathFileFromServer(
            _vm.storageSpaceMedia.storageSpaceMediaFilePath,
            _vm.storageSpaceMedia.defaultImg
          ),"className":'col-12'},on:{"changeValue":function($event){_vm.storageSpaceMedia.mediaFile = $event.file}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":"storageSpaceMediaFileShowStatusTypeToken","value":_vm.storageSpaceMedia.storageSpaceMediaFileShowStatusTypeToken,"options":_vm.showStatusTokenOptions,"title":_vm.$t('general.showStatus'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaFileShowStatusTypeToken =
              $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.storageSpaceMedia.fullCode,"title":_vm.$t('StorageSpaces.media.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaNameAr"),"errors":_vm.errors_storageSpaceMediaNameAr,"value":_vm.storageSpaceMedia.storageSpaceMediaNameAr,"title":_vm.$t('StorageSpaces.media.nameAr'),"imgName":'media.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaNameAr = $event;
            _vm.$v.storageSpaceMedia.storageSpaceMediaNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaNameEn"),"errors":_vm.errors_storageSpaceMediaNameEn,"value":_vm.storageSpaceMedia.storageSpaceMediaNameEn,"title":_vm.$t('StorageSpaces.media.nameEn'),"imgName":'media.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaNameEn = $event;
            _vm.$v.storageSpaceMedia.storageSpaceMediaNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaNameUnd"),"value":_vm.storageSpaceMedia.storageSpaceMediaNameUnd,"title":_vm.$t('StorageSpaces.media.nameUnd'),"imgName":'media.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaDescriptionAr"),"errors":_vm.errors_storageSpaceMediaDescriptionAr,"value":_vm.storageSpaceMedia.storageSpaceMediaDescriptionAr,"title":_vm.$t('StorageSpaces.media.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaDescriptionAr = $event;
            _vm.$v.storageSpaceMedia.storageSpaceMediaDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaDescriptionEn"),"errors":_vm.errors_storageSpaceMediaDescriptionEn,"value":_vm.storageSpaceMedia.storageSpaceMediaDescriptionEn,"title":_vm.$t('StorageSpaces.media.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaDescriptionEn = $event;
            _vm.$v.storageSpaceMedia.storageSpaceMediaDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaDescriptionUnd"),"value":_vm.storageSpaceMedia.storageSpaceMediaDescriptionUnd,"title":_vm.$t('StorageSpaces.media.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-storageSpaceMediaNotes"),"value":_vm.storageSpaceMedia.storageSpaceMediaNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.storageSpaceMedia.storageSpaceMediaNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }