<template>
  <div v-if="storageSpaceMediaData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("StorageSpaces.media.data") }}</th>
          <th rowspan="2">{{ $t("general.showStatus") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(storageSpaceMedia, index) in storageSpaceMediaData"
          :key="index"
        >
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table no-border"
              :src="
                fullPathFileFromServer(
                  storageSpaceMedia.storageSpaceMediaFilePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(storageSpaceMedia.fullCode) }}</td>
          <td>
            {{ isDataExist(storageSpaceMedia.storageSpaceMediaNameCurrent) }}
          </td>
          <td>
            {{
              isDataExist(
                storageSpaceMedia.storageSpaceMediaFileShowStatusTypeNameCurrent
              )
            }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <button
                  @click="
                    setStorageSpaceMediaData(storageSpaceMedia);
                    openBottomSheet('StorageSpaceMediaInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setStorageSpaceMediaData(storageSpaceMedia);
                    openBottomSheet('StorageSpaceMediaQRCode');
                  "
                  :title="$t('QR.modelName')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeEdit">
                <button
                  @click="
                    setStorageSpaceMediaData(storageSpaceMedia);
                    openBottomSheet('StorageSpaceMediaUpdate');
                  "
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeFinaleDelete">
                <button
                  v-b-modal.StorageSpaceMediaDelete
                  :title="$t('delete')"
                  @click="setStorageSpaceMediaData(storageSpaceMedia)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeChangeActivationType">
                <button
                  v-b-modal.StorageSpaceMediaChangeActivationType
                  :title="$t('changeActivationType')"
                  @click="setStorageSpaceMediaData(storageSpaceMedia)"
                >
                  <img src="@/assets/images/changeActivationType.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setStorageSpaceMediaData(storageSpaceMedia);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasStorageSpaceMediaEmployeeEdit,
  hasStorageSpaceMediaEmployeeFinaleDelete,
  hasStorageSpaceMediaEmployeeChangeActivationType,
  hasStorageSpaceMediaPlaceEdit,
  hasStorageSpaceMediaPlaceFinaleDelete,
  hasStorageSpaceMediaPlaceChangeActivationType,
} from "./../../../utils/privilegeHelper";
import FloatingMenu from "./../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  name: "StorageSpaceMediasTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["storageSpaceMediaData", "filterData", "defaultImg"],
  data() {
    return {
      modelName: this.$route.meta.modelName,
    };
  },
  methods: {
    setStorageSpaceMediaData(storageSpaceMedia) {
      this.$emit("setStorageSpaceMediaData", storageSpaceMedia);
    },
    isDataExist,
    fullPathFileFromServer,
  },
  computed: {
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(hasStorageSpaceMediaEmployeeEdit());
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(hasStorageSpaceMediaPlaceEdit());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(hasStorageSpaceMediaEmployeeFinaleDelete());
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(hasStorageSpaceMediaPlaceFinaleDelete());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "storageSpaceMediasEmployees":
          status = checkPrivilege(
            hasStorageSpaceMediaEmployeeChangeActivationType()
          );
          break;
        case "storageSpaceMediasPlaces":
          status = checkPrivilege(
            hasStorageSpaceMediaPlaceChangeActivationType()
          );
          break;
        default:
          break;
      }
      return status;
    },
  },
};
</script>
